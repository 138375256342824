import React, { useEffect, useState, createContext, useContext } from "react"
import { useCoords } from "../../store/CoordsContext"
import { Element } from "react-scroll"
import Container from "../../components/Container/Container"
import Box from "components/Box"
import Typography from "components/Typography"
import SvgCircleAnimation from "../../components/Circles/SvgCircleAnimation"
import { SectionElement, Intro } from "../../components/Container/styles"
import { H3, H1 } from "../../components/Typo/Headlines"
import { ExcerptParagraphs, Paragraphs } from "components/Typo/Text"
import SvgRibbon from "../../components/Ribbon/SvgRibbon"
import SeeMore from "../../components/Ribbon/SeeMore"
import FixedModal from "../../components/Modal/FixedModal"
import FixedModalFullPage from "../../components/Modal/FixedModalFullPage"
import GalleryModal from "../../components/Modal/GalleryModal"
import SectionGrid from "../../components/Container/SectionGrid"
import Audio from "../../components/Player/Audio"
import {
  SeeMoreContainer,
  SectionImageWithBorder,
  SectionFigcaptionWithBorder,
  SectionFigureWithBorder,
} from "../../components/Container/styles"
import { ModalContext } from "../../store/ModalContext"
import { FullPageModalContext } from "../../store/FullPageModalContext"
import { GalleryModalContext } from "../../store/GalleryModalContext"
import { isMobile, isDesktop } from "utils"
import SEO from "../../components/seo"
import { PlayerContext } from "../../store/PlayerContext"
import MobilePlayer from "../../components/Player/MobilePlayer"
import { AnimatePresence, motion, useMotionValue } from "framer-motion"
import { FullSpecContext } from "../../store/FullSpecContext"
import { getGPUTier } from "detect-gpu"

const Ziemia = ({ pageContext, location }) => {
  // TODO: only query & seo in this component, pass data to generic component

  const { sections } = pageContext?.post?.fields
  const [modal, setModal] = useState(null)
  const [fullPageModal, setFullPageModal] = useState(null)
  const [galleryModal, setGalleryModal] = useState(null)
  const [points, setPoints] = useState(null)
  const [opacity, setOpacity] = useState(1)
  const [coords, setCoords] = useCoords()
  const [height, setHeight] = useState("calc(100vh - 135px)")
  const playerContext = useContext(PlayerContext)
  const [showComponent, setShowComponent] = useState(false)
  const paralaxX = useMotionValue(0)
  const paralaxY = useMotionValue(0)

  useEffect(() => {
    //console.log("planet coords", coords)
  }, [coords])

  useEffect(() => {
    if (pageContext.post.fields.audio) {
      const track = pageContext.post.fields.audio
      const url = track.url ? track.url : track.file
      playerContext.setPlayerContext({
        type: "SET_PLAYER",
        payload: {
          title: track.title,
          url: url,
          playing: false,
          description: track.description,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (modal) {
      setOpacity(0.4)
    } else if (fullPageModal) {
      setOpacity(0)
    } else {
      setOpacity(1)
    }
  }, [modal, fullPageModal])

  useEffect(() => {
    if (isMobile) {
      setHeight("100vh")
    } else if (isDesktop) {
      setHeight("calc(100vh - 135px)")
    } else {
      setHeight("calc(100vh - 150px)")
    }

    let intro
    ;(async () => {
      const detectLowSpec = async () => {
        const gpuTier = await getGPUTier()

        if (gpuTier.tier > 1) {
          if (!showComponent) {
            intro = setTimeout(() => {
              setShowComponent(true)
            }, 5000)
          }
        } else {
          // setLowSpecPC(true)
          intro = setTimeout(() => {
            setShowComponent(true)
          }, 100)
        }
      }
      await detectLowSpec()
    })()

    return () => {
      intro && clearTimeout(intro)
    }
  }, [])

  useEffect(() => {
    if (coords) {
      setShowComponent(true)
    }
  }, [coords])

  // if (!showComponent) {
  //   return null
  // }

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      <FullPageModalContext.Provider
        value={{ fullPageModal, setFullPageModal }}
      >
        <GalleryModalContext.Provider value={{ galleryModal, setGalleryModal }}>
          <SEO
            lang={"pl"}
            title={"Biografia | Galaktyka Lema"}
            description={
              "Lwów, lata sowieckiej i niemieckiej okupacji, pierwsze dzieła i ogromne sukcesy. Biografia najwybitniejszego polskiego fantasty."
            }
            image={"/lem.png"}
          >
            {/* TODO: Rozalia */}
            <link
              rel="cannonical"
              href="https://galaktykalema.pl/pl/biografia"
            />
            <link
              rel="alternate"
              hreflang="pl"
              href="https://galaktykalema.pl/pl/biografia"
            />
            <link
              rel="alternate"
              hreflang="en"
              href="https://lemgalaxy.com/en/biografia"
            />
          </SEO>

          <AnimatePresence>
            {(showComponent || isMobile) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Container>
                  <div style={{ position: "relative" }}>
                    <SvgCircleAnimation
                      pageContext={pageContext}
                    ></SvgCircleAnimation>
                  </div>
                  <Element
                    id={"scene"}
                    containerId={"scene"}
                    // onScroll={(e) => {
                    //   //console.log("Scroll", scroll)
                    // }}
                    style={{
                      // backgroundColor: "red",
                      overflowY: "auto",
                      height: height,
                      opacity: opacity,
                      zIndex: fullPageModal ? 0.4 : 1,
                    }}
                  >
                    <SectionElement>
                      <Intro>
                        <H1 content={"url(/titles/4_biografia.svg)"}>
                          {pageContext.post.post_title}
                        </H1>
                        <ExcerptParagraphs
                          dangerouslySetInnerHTML={{
                            __html: pageContext.post.post_content,
                          }}
                        ></ExcerptParagraphs>
                        {pageContext?.post?.fields?.audio?.url && (
                          <Audio track={pageContext.post.fields.audio} />
                        )}
                      </Intro>
                      {sections.map((x, i) => {
                        return (
                          <Element
                            className={`test${i}`}
                            name={`element_${i}`}
                            key={i}
                          >
                            <section style={{ marginBottom: "50px" }}>
                              <SectionGrid layout={x.layout}>
                                {x.title && (
                                  <Box
                                    display="flex"
                                    alignItems="baseline"
                                    marginLeft={isMobile ? -32 : -40}
                                  >
                                    <img
                                      src="/chapter_icon.svg"
                                      width="29"
                                      height="auto"
                                    />
                                    {i < 9 ? (
                                      <Typography
                                        variant="chapterIndex"
                                        color="text.secondary"
                                        marginRight="7px"
                                        marginLeft={isMobile ? "5px" : "9px"}
                                      >
                                        0{i + 1}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant="chapterIndex"
                                        color="text.secondary"
                                        marginRight="7px"
                                        marginLeft={isMobile ? "5px" : "9px"}
                                      >
                                        {i + 1}
                                      </Typography>
                                    )}
                                    <H3>{x.title}</H3>
                                  </Box>
                                )}
                                <div>
                                  <Paragraphs
                                    dangerouslySetInnerHTML={{
                                      __html: x.content,
                                    }}
                                  ></Paragraphs>
                                  {x.popup && x?.popup?.title && (
                                    <SvgRibbon
                                      popup={x.popup}
                                      title={x.popup.title}
                                    ></SvgRibbon>
                                  )}
                                </div>
                                {x.Image && !x.points && (
                                  <SectionFigureWithBorder>
                                    <SectionImageWithBorder
                                      onClick={() => setGalleryModal(x)}
                                      src={x.Image.url}
                                    />
                                    <SectionFigcaptionWithBorder>
                                      {x.Image.caption}
                                    </SectionFigcaptionWithBorder>
                                  </SectionFigureWithBorder>
                                )}
                                {x.Image && x.points && (
                                  <SeeMoreContainer>
                                    <SectionFigureWithBorder>
                                      <SectionImageWithBorder
                                        style={{ cursor: "auto" }}
                                        src={x.Image.url}
                                      ></SectionImageWithBorder>
                                      <SectionFigcaptionWithBorder>
                                        {x.Image.caption}
                                      </SectionFigcaptionWithBorder>
                                    </SectionFigureWithBorder>
                                    <SeeMore
                                      title={"Poznaj historię"}
                                      onClick={() => {
                                        setFullPageModal(x.points[0])
                                        setPoints(x.points)
                                      }}
                                    />
                                  </SeeMoreContainer>
                                )}
                              </SectionGrid>
                            </section>
                          </Element>
                        )
                      })}
                    </SectionElement>
                    <div style={{ marginBottom: "100px" }}></div>
                  </Element>
                  <MobilePlayer></MobilePlayer>
                </Container>
              </motion.div>
            )}
          </AnimatePresence>

          <FixedModal modal={modal} closeModal={() => setModal(null)} />
          <FixedModalFullPage
            modal={fullPageModal}
            closeModal={() => setFullPageModal(null)}
            points={points}
            legend1={"II Rzeczpospolita"}
            legend2={"Okupacja i rządy Sowietów - 1939-1941; 1944-1946"}
            legend3={"Okupacja niemiecka - 1941-1944"}
            en={false}
          />
          <GalleryModal
            modal={galleryModal}
            closeModal={() => setGalleryModal(null)}
          />
        </GalleryModalContext.Provider>
      </FullPageModalContext.Provider>
    </ModalContext.Provider>
  )
}

export default Ziemia
